.navbar-container:not(.nav-logo) {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 2%;
  width: 80vw;
  position: fixed;
  margin: auto;
  left: 50%;
  transform: translate(-50%, 50%);
  height: var(--nav-height);
  top: -3px;
  transition: all 0.2s ease-in-out;
  z-index: 100;
  background: rgb(101 101 101 / 18%);
  border-radius: 6px;
  box-shadow: 0 4px 30px rgb(148 0 214 / 11%);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}

.nav-link .arrow {
  display: inline-block;
  transition: transform 0.3s ease;
}
.nav-toggle {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.nav-toggle span {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px;
  background: white;
  transition: all 0.5s;
}
.dropdown-menu {
  display: none;
  position: absolute;
  top: 103%;
  left: 0;
  background: rgb(47 47 47 / 80%);
  box-shadow: 0 2px 15px rgb(148 0 214 / 11%);
  /* padding: 10px; */
  z-index: 1000;
  transition: all 0.3s ease;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  width: max-content;
}

.dropdown-menu a {
  display: block;
  padding: 5px 10px;
  text-decoration: none;
  margin: 0 !important;
  color: black;
}

.navbar-dropdown:hover .dropdown-menu {
  display: block;
}

.navbar-dropdown:hover .arrow {
  transform: rotate(180deg);
}

.navbar-container a {
  transition: 0.3s;
}

.navbar-container .nav-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  left: 10px;
  gap: 10px;
}

.navbar-container.scrolling {
  background: #2f2f2fd4;
  transition: all 0.2s ease-in-out;
}

.navbar-container a {
  text-decoration: none;
  color: white;
  font-size: 16px;
  margin: 0 10px;
}

.navbar-container a:hover {
  color: #bf75ff;
}

.navbar-container a.selected {
  color: #b560ff;
}

.svg-code-logo {
  cursor: pointer;
}

.navbar-dropdown {
  position: relative;
}

.navbar-dropdown-content-container {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  position: absolute;
  background-color: #9c9c9c26;
  backdrop-filter: blur(5.5px);
  box-shadow: 3px 5px 10px rgb(72 7 97 / 72%);
  -webkit-backdrop-filter: blur(5.5px);
  top: calc(100% + 5px);
  left: 50%;
  transform: translate(-50%, 0);
}

.options-list div {
  padding: 10px;
}

.options-list div:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.options-list div a {
  margin: 0;
}

.options-list div:hover {
  background: rgba(112, 112, 112, 0.653);
  transition: background 0.2s ease-in;
}


.nav-light-mode{
  margin-right: 1%;
  cursor: pointer;
  user-select: none;
}

.nav-toggle.open span:nth-child(1) {
  transform: rotate(45deg);
  top: 12px;
  position: absolute;
}

.nav-toggle.open span:nth-child(2) {
  opacity: 0;
}

.nav-toggle.open span:nth-child(3) {
  transform: rotate(-45deg);
  top: 12px;
  position: absolute;
}