@import url(Fonts/LexendDeca/LexendDeca.css);
:root {
  --nav-height: 60px;
}
.page-container {
  margin-top: calc(var(--nav-height) + 10px);
}

.page-container-description, .page-container-projects {
  margin-top: calc(var(--nav-height) + 8vh);
}
body {
  background: #262626;
  color: rgb(210, 210, 210);
  margin: 0;
  font-family: "Lexend Deca", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: "workbench", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
  font-weight: 100;
}
.keyword {
  color: #b560ff;
  font-weight: bold;
}
.php {
  color: #6280b6;
}
.js {
  color: #ffca26;
}
.mongo {
  color: #469f45;
}
.react {
  color: #00d8fe;
}
.python {
  color: #3571a2;
}

/* light mode */

.l {
  background: rgb(210, 210, 210);
  color: #262626;
}
.l .timeline {
  background: #6c6c6c;
}
.l .navbar-container:not(.nav-logo) {
  background: rgb(238 238 238 / 51%);
}

.l .navigator-element {
  background: rgb(255 255 255 / 38%);
}
.l .navbar-container.scrolling {
  background: #e9e9e9e8;
  color: black;
}
.l .navbar-container.scrolling .dropdown-menu {
  background: #e3e3e3de;
}
.l .navbar-container a {
  color: black;
}
/* .l .dropdown-menu {
  background: rgb(215 215 215 / 73%);
} */

.l .keyword {
  color: #9b27ff;
}

.l .js {
  color: #cb9900;
}
.l .react {
  color: #00aac8;
}
.l .propic-image {
  background: radial-gradient(
    circle at center,
    rgb(2 0 4) 0%,
    rgb(157 94 212 / 74%) 100%,
    transparent
  );
}

.l .MuiFilledInput-input {
  color: black !important;
}
.MuiFilledInput-input {
  color: black !important;
}
.l .mail-field .Mui-focused,
.l .MuiFormLabel-root.Mui-focused{
  color: #8b13f5 !important;
}
.l .MuiFilledInput-root::after{
  border-bottom: 2px solid #8b13f5 !important;
}

.mail-field .Mui-focused,
.MuiFormLabel-root.Mui-focused{
  color: #9b27ff !important;
}
.MuiFilledInput-root::after{
  border-bottom: 2px solid #9b27ff !important;
}

.MuiFilledInput-root:not(.l .MuiFilledInput-root){
  background: rgb(255 255 255 / 84%) !important;
}
/* .MuiInputLabel-root:not(.l .MuiInputLabel-root), */
.MuiFormHelperText-root:not(.l .MuiFormHelperText-root) {
  color: rgb(255 255 255 / 68%) !important;
}
@media screen and (max-width: 1000px) {
  .progress-keypoint{
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .navbar-container.expanded .nav-logo {
    left: 2vh;
    top: 1vh;
  }
  .navbar-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
    width: 100%;
    height: auto;
    padding: 10px;
    background: rgb(47 47 47 / 80%);
  }

  .nav-link {
    display: none;
    width: 100%;
    padding: 10px 0;
    text-align: center;
  }

  .navbar-container.expanded .nav-link {
    display: block;
  }

  .navbar-container.expanded:not(.nav-logo) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgb(47 47 47 / 90%);
    z-index: 9999999;
    transform: none;
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 0;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(3px);
  }

  .l .navbar-container.expanded:not(.nav-logo) {
    background: rgba(213, 213, 213, 0.9);
  }

  .nav-toggle span {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px;
    background: white;
  }
  .l .nav-toggle span {
    background: rgb(0, 0, 0);
  }
  .propic-image {
    width: 28vh;
    margin-top: 5vh;
  }
  .l .navbar-container .nav-links {
    background: rgb(231 231 231 / 85%);
  }

}
